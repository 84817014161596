import React, { useEffect, useRef, useState } from 'react';
import MaskedInput, { conformToMask } from 'react-text-mask';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import { scrollInputToCenter } from '../../../utils/helpers/common';
import { StyledTextField, StyledTextLabel, TextFieldContainer } from './styled';

export const MaskedPhoneInput = ({
  icon,
  name,
  onClick,
  disabled = false,
  placeholder
}) => {
  const { change } = useForm();
  const { values } = useFormState();
  const mask = [
    '(',
    '0',
    /[1-9]/,
    /\d/,
    ')',
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/
  ];
  const value = values[name] === undefined ? '' : values[name];
  const formattedValue = conformToMask(value, mask).conformedValue;
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef(null);
  const inputElement = inputRef.current?.inputElement;

  useEffect(() => {
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    if (inputElement) {
      inputElement.addEventListener('focus', handleFocus);
      inputElement.addEventListener('blur', handleBlur);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('focus', handleFocus);
        inputElement.removeEventListener('blur', handleBlur);
      }
    };
  }, [inputRef.current]);

  const focusInput = () => {
    inputElement?.focus();
  };

  const handleInputChange = e => {
    const rawValue = e.target.value.replace(/[^\d]/g, '');
    change(name, rawValue);
  };

  const scrollHandler = () => scrollInputToCenter(inputElement);

  useEffect(() => {
    handleInputChange({ target: { value } });
  }, [value]);

  return (
    <TextFieldContainer data-cy="text-field-container">
      <StyledTextField onClick={onClick} data-cy="text-field">
        <StyledTextLabel
          role="presentation"
          onClick={focusInput}
          isActive={isFocused || value}
        >
          {placeholder}
        </StyledTextLabel>
        <MaskedInput
          mask={mask}
          guide={false}
          ref={inputRef}
          pattern="[0-9]*"
          autoComplete="off"
          inputMode="numeric"
          disabled={disabled}
          data-cy="phone-input"
          onFocus={scrollHandler}
          onChange={handleInputChange}
          className="masked-input-field"
          value={value ? formattedValue : value}
        />
      </StyledTextField>
      {icon && (
        <Icon IconComponent={icon} width={35} height={35} indent={false} />
      )}
    </TextFieldContainer>
  );
};

MaskedPhoneInput.propTypes = {
  icon: PropTypes.func,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired
};
