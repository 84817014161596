import styled, { keyframes } from 'styled-components';

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const Loader = styled.span`
  --size: ${({ size }) => size || '16px'};
  display: block;
  height: var(--size);

  &::after {
    --fill: ${({ theme: { colors } }) => colors.background.primary};
    --border: 2px;
    content: '';
    display: inline-block;
    width: var(--size);
    height: var(--size);
    border-radius: 50%;
    border-top: var(--border) solid transparent;
    border-right: var(--border) solid var(--fill);
    border-bottom: var(--border) solid var(--fill);
    border-left: var(--border) solid var(--fill);
    transform: translateZ(0);
    animation: ${rotation} 1s infinite linear;
  }
`;
