import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-final-form';
import Icon from '../../Icon';
import { FORM_VALUES } from '../../../utils/helpers/constants';
import { validateNumField } from '../../../utils/helpers/validateItemForm';
import { scrollInputToCenter } from '../../../utils/helpers/common';
import {
  StyledField,
  StyledTextField,
  StyledTextLabel,
  TextFieldContainer
} from './styled';

export const TextField = ({
  stat,
  name,
  icon,
  value,
  format,
  onClick,
  disabled = false,
  inputMode = 'text',
  maxLength,
  placeholder,
  isFloat = false,
  styleType = 'default'
}) => {
  const { change } = useForm();
  const isNum = inputMode === 'numeric';
  const numRegex = isFloat ? '[0-9.,]*' : '[0-9]*';
  const inputRef = useRef();
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => {
    if (!value) setIsFocused(false);
  };

  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement?.addEventListener('focus', handleFocus);
      inputElement?.addEventListener('blur', handleBlur);

      return () => {
        inputElement?.removeEventListener('focus', handleFocus);
        inputElement?.removeEventListener('blur', handleBlur);
      };
    }
  }, []);

  const handleChange = e => {
    if (disabled) {
      e.preventDefault();
      return;
    }

    if (isNum) {
      const { isValid, val } = validateNumField(isFloat, e.target);
      if (isValid) change(FORM_VALUES[name], val);
      else e.preventDefault();
    } else change(FORM_VALUES[name], e.target.value);
  };

  const parseValue = val => (isNum && isFloat ? val.replace(',', '.') : val);

  const handleClick = () => {
    if (onClick) onClick();
    handleFocus();
  };

  const scrollHandler = () => scrollInputToCenter(inputRef.current);

  return (
    <TextFieldContainer data-cy="text-field-container">
      <StyledTextField
        data-stat={stat}
        data-cy="text-field"
        onClick={handleClick}
        onFocus={handleFocus}
      >
        <StyledTextLabel
          role="presentation"
          data-cy="text-field-label"
          isBold={styleType === 'bold'}
          isActive={isFocused || !!value}
        >
          {placeholder}
        </StyledTextLabel>
        <StyledField
          name={name}
          value={value}
          ref={inputRef}
          format={format}
          formatOnBlur={isFloat}
          component="input"
          autoComplete="off"
          disabled={disabled}
          maxLength={maxLength}
          onFocus={scrollHandler}
          onChange={handleChange}
          data-cy="text-field-input"
          isBold={styleType === 'bold'}
          pattern={isNum ? numRegex : null}
          parse={isNum ? parseValue : v => v}
          inputMode={isFloat ? 'decimal' : inputMode}
        />
      </StyledTextField>
      {icon && (
        <Icon IconComponent={icon} width={35} height={35} indent={false} />
      )}
    </TextFieldContainer>
  );
};

TextField.propTypes = {
  icon: PropTypes.func,
  stat: PropTypes.string,
  format: PropTypes.func,
  isFloat: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  styleType: PropTypes.oneOf(['default', 'bold']),
  inputMode: PropTypes.oneOf(['text', 'numeric']),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};
