import styled, { css } from 'styled-components';
import { Field } from 'react-final-form';
import { customScrollbar } from '../../../styled/common';
import { tagStyles } from '../Typography/styles';

export const maskedInputField = css`
  height: 100%;
  width: 100%;
  font-size: 15px;
  border: 0;
  outline: none;
  padding: 28px 0 9px;
  font-weight: 400;
  color: ${({ theme: { colors } }) => colors.text.primary};
  background-color: transparent;
  z-index: 2;
`;

export const TextFieldContainer = styled.div`
  width: 100%;
  display: flex;
  height: 55px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTextField = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 55px;
  justify-content: center;
  flex-direction: column;
  position: relative;

  .masked-input-field {
    ${maskedInputField}
  }
`;

export const StyledTextLabel = styled.div`
  position: absolute;
  left: 0;
  top: ${({ isNumber }) => (isNumber ? '-9px' : 'unset')};
  transition: transform 0.1s ease-in-out;
  color: ${({ theme: { colors } }) => colors.text.secondary};
  z-index: 1;
  ${({ isActive }) => tagStyles[isActive ? 'body_1' : 'body_2']}

  ${({ isActive, isBold }) =>
    isActive && `transform: translateY(-${isBold ? 15 : 10}px);`}
`;

export const StyledField = styled(Field)`
  height: 100%;
  width: 100%;
  border: 0;
  outline: none;
  padding: 28px 0 9px;
  color: ${({ theme: { colors } }) => colors.text.primary};
  background-color: transparent;
  z-index: 2;
  ${({ isBold }) => tagStyles[isBold ? 'numbers_1' : 'body_1']}
`;

export const StyledAreaField = styled(Field)`
  resize: none;
  width: 100%;
  height: 100%;
  min-height: 88px;
  max-height: 268px;
  ${tagStyles.body_1}
  padding-right: 10px;
  border: 0;
  outline: none;
  color: ${({ theme: { colors } }) => colors.text.primary};
  font-family: inherit;

  &::placeholder {
    ${tagStyles.body_1}
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme: { colors } }) => colors.text.secondary};
  }
`;

export const TextAreaContainer = styled.div`
  ${customScrollbar(0)}
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${({ theme: { colors } }) => colors.border.primary};
  border-radius: 7px;
  padding: 15px 10px 15px 15px;
`;

export const NumberFieldContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : 'calc(50% - 7px)')};
  margin-right: ${({ isFullWidth }) => (isFullWidth ? '0' : '15px')};

  &:last-child {
    margin-right: 0;
  }
`;

export const StyledDeleteIcon = styled.div`
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0;
  top: -2px;
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
`;

export const StyledNumberField = styled(Field)`
  display: block;
  width: 100%;
  padding: 0 20px 9px 0;
  height: 26px;
  ${tagStyles.body_1}
  border: 0;
  outline: none;
  border-radius: 0;
  border-bottom: 0.5px solid ${({ theme: { colors } }) => colors.border.primary};
  margin-bottom: 5px;

  ::placeholder {
    color: ${({ theme: { colors } }) => colors.text.secondary};
    opacity: 1;
  }
`;

export const StyledSearchContainer = styled.div`
  flex: 1 0;
  display: flex;
  align-items: center;
  border-radius: 14px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};
  padding: 0 15px;
  border: 1px solid ${({ theme: { colors } }) => colors.border.primary};
  background-color: ${({ theme: { colors } }) => colors.background.tertiary};
`;

export const StyledSearchInput = styled.input`
  flex: 1 1;
  border: 0;
  height: 48px;
  outline: none;
  ${tagStyles.body_1}
  appearance: none;
  border-radius: 14px;
  box-sizing: border-box;
  font-family: ${({ theme }) => theme.fontFamily};
  width: calc(100% - 10px);
  padding: 10px 0 10px 10px;
  background-color: transparent;
  color: ${({ theme: { colors } }) => colors.text.primary};

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    appearance: none;
  }

  &::placeholder {
    padding-left: 5px;
    ${tagStyles.body_1}
    font-family: ${({ theme }) => theme.fontFamily};
    color: ${({ theme: { colors } }) => colors.text.secondary};
  }
`;

export const StyledSearchResetButton = styled.div`
  width: 15px;
  height: 15px;
  margin-left: 10px;
  box-sizing: border-box;
  background-size: 15px 15px;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('/static/images/new_search/icons/icon-delete.svg');
`;
