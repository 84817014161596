import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormState } from 'react-final-form';

export const ErrorField = ({
  field,
  cypress,
  setError,
  margin = 0,
  divider = true
}) => {
  const { errors, submitFailed, submitErrors } = useFormState();
  const isError =
    (submitFailed && errors[field]) || (submitErrors && submitErrors[field]);

  useEffect(() => {
    if (setError) {
      setError(Boolean(isError));
    }
  }, [isError]);

  return (
    <div style={{ marginBottom: margin }}>
      {divider && <div className={isError ? 'divider-error' : 'divider'} />}
      {isError && (
        <div className="item-modal__error-text" data-cy={cypress}>
          {errors[field] || submitErrors[field]}
        </div>
      )}
    </div>
  );
};

ErrorField.propTypes = {
  divider: PropTypes.bool,
  setError: PropTypes.func,
  cypress: PropTypes.string,
  field: PropTypes.string.isRequired,
  margin: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};
