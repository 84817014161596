import React from 'react';
import PropTypes from 'prop-types';
import { ButtonText, StyledButton } from './styled';
import { Loader } from './loader.styled';
import { ButtonType } from '../../../styled/theme/types';

const buttonTypes = [
  'default',
  'edit',
  'white',
  'delete',
  'green',
  'kapitalBank',
  'link',
  'signIn'
];

export const Button = ({
  type = 'button',
  name = null,
  text = '',
  children = null,
  disabled = false,
  loading = false,
  isSmall = false,
  state = ButtonType.default,
  styles = {},
  onClick,
  onKeyPress,
  stat,
  cypress,
  hover
}) => (
  <StyledButton
    data-cy={cypress}
    data-stat={stat}
    disabled={disabled}
    $isSmall={isSmall}
    state={state}
    type={type}
    name={name}
    onClick={onClick}
    onKeyPress={onKeyPress}
    styles={styles}
    hover={hover}
  >
    {loading ? (
      <Loader />
    ) : (
      <ButtonText tag="body_1" {...{ state, disabled }}>
        {children}
        {text}
      </ButtonText>
    )}
  </StyledButton>
);

Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func,
  stat: PropTypes.string,
  cypress: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isSmall: PropTypes.bool,
  state: PropTypes.oneOf(buttonTypes),
  styles: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  hover: PropTypes.string
};
