import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { MODAL_ANIMATION_TIME } from '../../../utils/helpers/constants';
import {
  StyledSearchContainer,
  StyledSearchInput,
  StyledSearchResetButton
} from './styled';
import Icon from '../../Icon';
import SearchIcon from '../../../public/static/images/search/icons/icon-search.svg';
import PinIcon from '../../../public/static/images/new_search/icons/icon-pin.svg';

export const NewInput = ({
  id,
  stat,
  value,
  refEl,
  onKeyDown,
  placeholder,
  onBlurHandler,
  onResetHandler,
  onFocusHandler,
  onChangeHandler,
  isPin = false,
  inputMode = 'text',
  isAutoFocus = false,
  isShowReset = false,
  isInsideModalWithAnimation = false
}) => {
  useEffect(() => {
    if (isAutoFocus && refEl.current) {
      if (isInsideModalWithAnimation) {
        setTimeout(() => {
          refEl.current.focus();
        }, MODAL_ANIMATION_TIME);
      } else {
        refEl.current.focus();
      }
    }
  }, [isAutoFocus]);

  return (
    <StyledSearchContainer data-stat={stat} isPin={isPin}>
      <Icon
        color="white"
        indent={false}
        width={isPin ? 18 : 24}
        height={isPin ? 18 : 24}
        IconComponent={isPin ? PinIcon : SearchIcon}
      />
      <StyledSearchInput
        id={id}
        ref={refEl}
        type="search"
        name="search"
        value={value}
        tabIndex={-1}
        autoComplete="off"
        onKeyDown={onKeyDown}
        inputMode={inputMode}
        data-cy="search-input"
        onBlur={onBlurHandler}
        onFocus={onFocusHandler}
        placeholder={placeholder}
        onChange={onChangeHandler}
        pattern={inputMode === 'numeric' ? '[0-9]*' : null}
      />
      {isShowReset && (
        <StyledSearchResetButton
          role="button"
          tabIndex={-1}
          onClick={onResetHandler}
          onKeyPress={onResetHandler}
        />
      )}
    </StyledSearchContainer>
  );
};

NewInput.propTypes = {
  value: PropTypes.string.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  id: PropTypes.string,
  isPin: PropTypes.bool,
  stat: PropTypes.string,
  refEl: PropTypes.object,
  isAutoFocus: PropTypes.bool,
  isShowReset: PropTypes.bool,
  onBlurHandler: PropTypes.func,
  onFocusHandler: PropTypes.func,
  onResetHandler: PropTypes.func,
  isInsideModalWithAnimation: PropTypes.bool,
  inputMode: PropTypes.oneOf(['text', 'numeric'])
};
