import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { formatNumber } from '../../../utils/helpers';
import { StyledAreaField, TextAreaContainer } from './styled';

export const TextAreaField = forwardRef(
  (
    { stat, name, format, onClick, inputMode = 'text', placeholder, maxLength },
    ref
  ) => (
    <TextAreaContainer
      tabIndex={0}
      role="button"
      data-stat={stat}
      onClick={onClick}
      onKeyPress={onClick}
      data-cy="text-area-container"
    >
      <StyledAreaField
        ref={ref}
        name={name}
        multiple={true}
        format={format}
        autoComplete="off"
        component="textarea"
        maxLength={maxLength}
        data-cy="text-area-input"
        placeholder={placeholder}
        pattern={inputMode === 'numeric' ? '[0-9]*' : null}
        parse={inputMode === 'numeric' ? formatNumber : v => v}
      />
    </TextAreaContainer>
  )
);

TextAreaField.propTypes = {
  stat: PropTypes.string,
  onClick: PropTypes.func,
  format: PropTypes.func,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  inputMode: PropTypes.oneOf(['text', 'numeric'])
};

TextAreaField.displayName = 'TextAreaField';
