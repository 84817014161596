import React, { forwardRef, useRef } from 'react';
import { useForm, useFormState } from 'react-final-form';
import PropTypes from 'prop-types';
import Icon from '../../Icon';
import DeleteIcon from '../../../public/static/images/new_search/icons/icon-delete.svg';
import { useCombinedRefs } from '../../../hooks/useCombinedRefs';
import {
  NumberFieldContainer,
  StyledDeleteIcon,
  StyledNumberField,
  StyledTextLabel
} from './styled';

export const NumberField = forwardRef(
  (
    {
      name,
      placeholder,
      id,
      stat,
      value = '',
      onChange,
      onReset,
      isFullWidth,
      floatingPlaceholder,
      onFocusHandler,
      onBlurHandler
    },
    ref
  ) => {
    const { change } = useForm();
    const { values } = useFormState();
    const inputRef = useRef();
    const combinedRef = useCombinedRefs(ref, inputRef);

    const resetValue = () => {
      change(name);
      if (onReset) {
        onReset();
      }
    };

    const noLeadingZeroNumber = (val = '') => {
      if (typeof val === 'string' && val.startsWith('0') && val.length > 1) {
        return val.replace(/^0/, '').replace(/\D/g, '');
      }

      return val;
    };

    const onlyNums = (val = '') => {
      return typeof val === 'string' ? val.replace(/\D/g, '') : val;
    };

    const focusInput = () => {
      combinedRef.current?.focus();
    };

    const isFocused = document.activeElement === combinedRef.current;

    return (
      <NumberFieldContainer isFullWidth={isFullWidth}>
        {floatingPlaceholder && (
          <StyledTextLabel
            isNumber={true}
            role="presentation"
            onClick={focusInput}
            isActive={isFocused || value}
          >
            {floatingPlaceholder}
          </StyledTextLabel>
        )}

        <StyledNumberField
          ref={combinedRef}
          id={id}
          name={name}
          data-stat={stat}
          pattern="[0-9]*"
          component="input"
          autoComplete="off"
          inputMode="numeric"
          maxLength="9"
          parse={onlyNums}
          format={noLeadingZeroNumber}
          placeholder={placeholder}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
          {...(onChange && {
            onChange: e => {
              onChange(noLeadingZeroNumber(onlyNums(e.target.value)));
            },
            initialValue: value
          })}
        />
        <StyledDeleteIcon
          tabIndex={0}
          role="button"
          onClick={resetValue}
          onKeyPress={resetValue}
          isVisible={values[name]}
          data-cy="filters__delete-icon"
        >
          <Icon width={15} height={15} IconComponent={DeleteIcon} />
        </StyledDeleteIcon>
      </NumberFieldContainer>
    );
  }
);

NumberField.propTypes = {
  id: PropTypes.string,
  stat: PropTypes.string,
  value: PropTypes.string,
  onReset: PropTypes.func,
  onChange: PropTypes.func,
  isFullWidth: PropTypes.bool,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  floatingPlaceholder: PropTypes.string,
  onFocusHandler: PropTypes.func,
  onBlurHandler: PropTypes.func
};

NumberField.displayName = 'NumberField';
